"use client";
import { LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Tooltip, Typography } from "antd";
import React, { forwardRef, MutableRefObject, useEffect, useLayoutEffect, useRef, useState } from "react";
import { FileData, HunkData } from "react-diff-view";

import AvatarVariantFactory from "../data/AvatarVariantFactory";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import { TurnEventType } from "../data/SolverInterfaceEvent";
import {
    AgentThoughtTurnEvent,
    BisectEvent,
    BlameEvent,
    ChangeSet,
    CodeCoverageEvent,
    DocumentationEvent,
    EventChangeSetState,
    ExecutionEvent,
    FileImage,
    LinterErrorsEvent,
    MergeUserBranchEvent,
    OpenFileEvent,
    ProfileEvent,
    ProjectTreeEvent,
    RelevantFilesEvent,
    RemoteCommitsEvent,
    SolvingStoppedEvent,
    sessionIsLoading,
    SessionStatus,
    SolutionReviewEvent,
    TextSearchEvent,
    Turn,
    TurnEvent,
    useAmendTurn,
    useCanModifySession,
    useEventChangeSets,
    useExpandEventFile,
    useFetchChangeSet,
    useRevertHunk,
    useSession,
    useSessionStatus,
    useSolve,
    useTurns,
    useUndoTurn,
    WorkspaceCreationProgressEvent,
} from "../data/SolverSession";
import { ImmutableDiffCard } from "./ImmutableDiffCard";
import {
    DATA_ATTRIBUTE_CHANGES_END,
    DATA_ATTRIBUTE_CHANGES_START,
    DATA_ATTRIBUTE_EVENT_ID,
    DATA_ATTRIBUTE_EVENT_TYPE,
    MessageProps,
    MessageRefT,
} from "./Message";
import MessageGroup from "./MessageGroup";
import { MessageType } from "./MessageType";
import { MutableDiffCard } from "./MutableDiffCard";
import SolverMarkdown from "./SolverMarkdown";
import { ChangeSetSummary, FileInfo, getRelevantPath } from "./Utils";

import Bisect from "./messages/Bisect";
import Blame from "./messages/Blame";
import CodeCoverage from "./messages/CodeCoverage";
import Execution from "./messages/Execution";
import {
    OpenFileMessage,
    ProjectTreeMessage,
    TextSearchMessage,
    SolvingStoppedMessage,
} from "./messages/InlineMessage";
import LinterCard from "./messages/LinterCard";
import MergeUserBranch from "./messages/UserBranchMerge";
import Profile from "./messages/Profile";
import RelevantFilesCard from "./messages/RelevantFilesCard";
import SolutionReview from "./messages/SolutionReview";
import WorkspaceProgress from "./messages/WorkspaceProgress";

import { ChangesContent, PlanEvent, SolvingStoppedContent, WorkspaceCreationProgress } from "../data/TurnEventContent";
import solverAvatar from "../images/solver_icon_reverse_borderless.png";
import "./Conversation.css";
import Plan from "./messages/Plan";
import RemoteCommits from "./messages/RemoteCommits";
import UserBranchMerge from "./messages/UserBranchMerge";

interface ConversationProps {
    restoreScrollPosition: () => void;
    onShowChangesView: () => void;
    onReportIssue: () => void;
}

export type ContainerRefT = HTMLElement;

const Conversation = forwardRef<ContainerRefT, ConversationProps>(
    ({ restoreScrollPosition, onShowChangesView, onReportIssue }, containerRef) => {
        const turns = useTurns();
        const eventChangeSets = useEventChangeSets();
        const session = useSession();
        const sessionStatus = useSessionStatus();
        const canModifySession = useCanModifySession();
        const revertHunk = useRevertHunk();
        const amendTurn = useAmendTurn();
        const undoTurn = useUndoTurn();
        const expandEventFile = useExpandEventFile();
        const fetchChangeSet = useFetchChangeSet();
        const solve = useSolve();
        const { activeRepo } = useSolverInterfaceContext();

        const [userDidScroll, setUserDidScroll] = useState(false);

        const messageRefs = useRef<(MessageRefT | null)[][]>([]);

        const handleSuggestionClick = (suggestion: string) => {
            if (!canModifySession()) return;

            solve(suggestion);
        };

        useLayoutEffect(() => {
            restoreScrollPosition();
        }, []);

        useEffect(() => {
            const containerElement = getContainerElement();
            if (!containerElement) return;

            const onScroll = () => {
                setTimeout(() => {
                    setUserDidScroll(!elementIsFullyScrolled(containerElement));
                }, 100);
            };

            containerElement.addEventListener("scroll", onScroll);

            return () => containerElement.removeEventListener("scroll", onScroll);
        }, []);

        useEffect(() => {
            if (sessionStatus === SessionStatus.SOLVING) {
                scrollToBottom();
            }
        }, [sessionStatus, turns, eventChangeSets, userDidScroll]);

        const scrollToBottom = () => {
            const containerElement = getContainerElement();
            if (!containerElement) return;

            // If the user scrolled up, don't scroll to the bottom.
            if (userDidScroll) {
                return;
            }

            containerElement.scrollTop = containerElement.scrollHeight;
        };

        const elementIsFullyScrolled = (element: HTMLElement) => {
            return Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 5;
        };

        const getContainerElement = (): HTMLElement | undefined => {
            const containerRefAsRef = containerRef as MutableRefObject<ContainerRefT | null>;

            if (!containerRefAsRef.current) return undefined;

            return containerRefAsRef.current;
        };

        const handleIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            entries.forEach((entry) => {
                // TODO: cancel if the event is not visible anymore, but still loading
                if (!entry.isIntersecting) return;

                const eventId = entry.target.getAttribute(DATA_ATTRIBUTE_EVENT_ID);
                const eventType = entry.target.getAttribute(DATA_ATTRIBUTE_EVENT_TYPE);
                const eventStart = entry.target.getAttribute(DATA_ATTRIBUTE_CHANGES_START);
                const eventEnd = entry.target.getAttribute(DATA_ATTRIBUTE_CHANGES_END);

                if (
                    eventId &&
                    eventType &&
                    eventStart &&
                    eventEnd &&
                    (eventType === TurnEventType.EDIT ||
                        eventType === TurnEventType.REVERT ||
                        eventType === TurnEventType.TURN_CHANGES)
                ) {
                    fetchChangeSet(
                        eventId,
                        eventType as TurnEventType.EDIT | TurnEventType.REVERT | TurnEventType.TURN_CHANGES,
                        eventStart,
                        eventEnd
                    );

                    observer.unobserve(entry.target);
                }
            });
        };

        useEffect(() => {
            const containerElement = getContainerElement();
            if (!containerElement) return;

            const observer = new IntersectionObserver(handleIntersection, {
                root: containerElement,
                rootMargin: "0px",
                threshold: 0.1,
            });

            const refs = messageRefs.current.flat();

            refs.forEach((msg) => {
                if (msg) {
                    observer.observe(msg);
                }
            });

            return () => {
                refs.forEach((msg) => {
                    if (msg) {
                        observer.unobserve(msg);
                    }
                });
            };
        }, [session]);

        const buildChangesMessageContent = (event_id: string, changeSet: ChangeSet, turn: Turn) => {
            return (
                <div className="turn-changes">
                    {buildExtra(changeSet)}
                    {buildDiffs(event_id, changeSet, turn)}
                </div>
            );
        };

        const buildDiffs = (event_id: string, changeSet: ChangeSet, turn: Turn) => {
            const mutable = turn.idx === turns.length - 1;
            const postimages = new Map<string, FileImage>(
                changeSet.postimages?.map((image: FileImage) => [image.file_path, image])
            );

            const diffCardKey = (fileData: FileData) => `${fileData.oldRevision}-${fileData.newRevision}`;

            // We collect diffs with their paths to sort them by path.
            const codeDiffsAndFilenames: [JSX.Element, string][] = changeSet.file_infos.map((fileInfo: FileInfo) => {
                const filePath = getRelevantPath(fileInfo.fileData);

                if (mutable) {
                    const postImage = postimages.get(getRelevantPath(fileInfo.fileData));
                    const highlights = fileInfo.fileData.hunks.map((hunk: HunkData, idx: number) => {
                        return {
                            startChange: hunk.changes[0],
                            endChange: hunk.changes[hunk.changes.length - 1],
                            revertHunkFn: () => revertHunk(turn.id, fileInfo.change_ids[idx]),
                            revertHunkDisabled: !canModifySession(),
                        };
                    });

                    return [
                        <MutableDiffCard
                            key={diffCardKey(fileInfo.fileData)}
                            fileInfo={fileInfo}
                            postImage={postImage}
                            hunkHighlights={highlights}
                            updateFileFn={(fileImage: FileImage) => amendTurn(turn.id, turn.nl_text, [fileImage])}
                            updateFileDisabled={!canModifySession()}
                            expandCodeFn={(start: number, end: number) =>
                                expandEventFile(event_id, filePath, start, end)
                            }
                        />,
                        getRelevantPath(fileInfo.fileData),
                    ];
                } else {
                    return [
                        <ImmutableDiffCard
                            key={diffCardKey(fileInfo.fileData)}
                            fileInfo={fileInfo}
                            expandCodeFn={(start: number, end: number) =>
                                expandEventFile(event_id, filePath, start, end)
                            }
                        />,
                        getRelevantPath(fileInfo.fileData),
                    ];
                }
            });

            codeDiffsAndFilenames.sort((a, b) => {
                return a[1].localeCompare(b[1]);
            });

            return codeDiffsAndFilenames.map((diff) => diff[0]);
        };

        const buildExtra = (changeSet: ChangeSet) => {
            if (sessionStatus === SessionStatus.SOLVING) {
                return undefined;
            }

            return <ChangeSetSummary changeSet={changeSet} />;
        };

        const turnThread = (turn: Turn) => {
            return (
                <React.Fragment key={turn.id}>
                    {promptMessage(turn)}
                    {eventMessages(turn)}
                </React.Fragment>
            );
        };

        const eventMessages = (turn: Turn) => {
            const isLastTurn: boolean = turn.idx === turns.length - 1;
            const events = turn.events.filter((turnEvent) => !shouldExcludeMessage(turnEvent));

            const messageProps: MessageProps[] = events.map((turnEvent: TurnEvent) => {
                let changesContent: ChangesContent | undefined = undefined;
                if (
                    turnEvent.event_type === TurnEventType.EDIT ||
                    turnEvent.event_type === TurnEventType.REVERT ||
                    turnEvent.event_type === TurnEventType.TURN_CHANGES
                ) {
                    changesContent = turnEvent.content as ChangesContent;
                }

                return {
                    key: turnEvent.id,
                    renderContent: () => eventMessageContent(turnEvent, turn),
                    messageType: MessageType.AGENT,
                    eventId: turnEvent.id,
                    eventType: turnEvent.event_type,
                    changesContent,
                    ...eventMessageCollapseProps(turnEvent),
                };
            });

            const messageGroupProps = {
                messages: messageProps,
                messageType: MessageType.AGENT,
                collapsible: !isLastTurn || (isLastTurn && !sessionIsLoading(sessionStatus)),
                collapsed: !isLastTurn,
                avatar: solverAvatar,
            };

            return (
                <MessageGroup
                    {...messageGroupProps}
                    ref={(msg) => {
                        if (!msg) return;
                        messageRefs.current[turn.idx] = msg;
                    }}
                />
            );
        };

        const shouldExcludeMessage = (turnEvent: TurnEvent): boolean => {
            if (turnEvent.event_type === TurnEventType.SOLVER_LOG) return true;
            if (!turnEvent.content) return true;

            if (turnEvent.event_type === TurnEventType.AGENT_THOUGHT) {
                const agentThought = turnEvent as AgentThoughtTurnEvent;
                return !agentThought.content.message || agentThought.content.message === "";
            } else if (turnEvent.event_type === TurnEventType.WORKSPACE_CREATION_PROGRESS) {
                const workspaceCreationProgress = (turnEvent as WorkspaceCreationProgressEvent).content.status;
                return workspaceCreationProgress === WorkspaceCreationProgress.DONE;
            } else if (turnEvent.event_type === TurnEventType.SOLVING_STOPPED) {
                const solvingStopped = turnEvent as SolvingStoppedEvent;
                return !solvingStopped.content.solving_error || solvingStopped.content.solving_error === "";
            }

            return false;
        };

        const promptMessage = (turn: Turn) => {
            if (!session) return undefined;
            if (turn.nl_text === "") return undefined;

            scrollToBottom();

            const isLastTurn: boolean = turn.idx === turns.length - 1;
            const avatarUrl = AvatarVariantFactory.createURLVariant(session.user_avatar_url, session.auth_type, 48);

            return (
                <MessageGroup
                    messages={[
                        {
                            renderContent: () => {
                                return (
                                    <div className="user-message-content">
                                        <SolverMarkdown text={turn.nl_text} />
                                        <div className="user-message-content-extra">
                                            {isLastTurn && buildUndoButton(turn)}
                                        </div>
                                    </div>
                                );
                            },
                            messageType: MessageType.USER,
                            collapsible: true,
                            collapsedThresholdPx: 400,
                            defaultExpanded: false,
                            key: turn.id,
                        },
                    ]}
                    messageType={MessageType.USER}
                    collapsible={false}
                    collapsed={false}
                    avatar={avatarUrl}
                />
            );
        };

        const buildUndoButton = (turn: Turn) => {
            const tooltipMessage = turn.allow_undo
                ? "Undo this thread"
                : "Cannot undo this thread because its changes have been pushed to remote";

            return (
                <Tooltip title={tooltipMessage} placement="left" arrow={true}>
                    <Button
                        className="undo-button"
                        size="small"
                        disabled={!canModifySession() || !turn.allow_undo}
                        onClick={(e) => {
                            e.stopPropagation();
                            undoTurn(turn.id);
                        }}
                    >
                        <span className="small-button-text">Undo</span>
                    </Button>
                </Tooltip>
            );
        };

        const eventMessageCollapseProps = (turnEvent: TurnEvent) => {
            if (turnEvent.event_type === TurnEventType.RELEVANT_FILES) {
                return {
                    collapsible: true,
                    defaultExpanded: false,
                };
            } else if (turnEvent.event_type === TurnEventType.LINT_ERRORS) {
                return {
                    collapsible: false,
                };
            } else if (turnEvent.event_type === TurnEventType.SOLUTION_REVIEW) {
                return {
                    collapsible: false,
                };
            } else if (turnEvent.event_type === TurnEventType.REMOTE_COMMITS) {
                return {
                    collapsible: false,
                };
            } else if (turnEvent.event_type === TurnEventType.MERGE_USER_BRANCH) {
                return {
                    collapsible: false,
                };
            } else if (
                turnEvent.event_type === TurnEventType.EDIT ||
                turnEvent.event_type === TurnEventType.TURN_CHANGES ||
                turnEvent.event_type === TurnEventType.REVERT
            ) {
                return {
                    collapsible: true,
                    defaultExpanded: false,
                    collapsedThresholdPx: 400,
                };
            }

            return {
                collapsible: true,
                defaultExpanded: true,
            };
        };

        const eventMessageContent = (turnEvent: TurnEvent, turn: Turn) => {
            switch (turnEvent.event_type) {
                case TurnEventType.AGENT_THOUGHT:
                    return <SolverMarkdown text={(turnEvent as AgentThoughtTurnEvent).content.message} />;
                case TurnEventType.OPEN_FILE:
                    return <OpenFileMessage openFileContent={(turnEvent as OpenFileEvent).content} />;
                case TurnEventType.TEXT_SEARCH:
                    return <TextSearchMessage textSearchContent={(turnEvent as TextSearchEvent).content} />;
                case TurnEventType.REMOTE_COMMITS:
                    if (!activeRepo) {
                        return null;
                    }

                    return (
                        <RemoteCommits
                            content={(turnEvent as RemoteCommitsEvent).content}
                            fullRepoName={activeRepo.full_name}
                        />
                    );
                case TurnEventType.MERGE_USER_BRANCH:
                    if (!session) {
                        return null;
                    }

                    return (
                        <UserBranchMerge
                            content={(turnEvent as MergeUserBranchEvent).content}
                            userBranchName={session.branch_name}
                        />
                    );
                case TurnEventType.PROJECT_TREE:
                    return <ProjectTreeMessage projectTreeContent={(turnEvent as ProjectTreeEvent).content} />;
                case TurnEventType.TURN_CHANGES: {
                    const turnChangesChangeSet = eventChangeSets.get(turnEvent.id);

                    if (!turnChangesChangeSet || turnChangesChangeSet.state === EventChangeSetState.LOADING) {
                        return (
                            <div className="message-loading">
                                <LoadingOutlined className="message-loading-icon" />
                            </div>
                        );
                    } else if (turnChangesChangeSet.state === EventChangeSetState.ERROR) {
                        return (
                            <Typography.Text>
                                <WarningOutlined /> Failed to load message
                            </Typography.Text>
                        );
                    }

                    return buildChangesMessageContent(turnEvent.id, turnChangesChangeSet.changeSet, turn);
                }
                case TurnEventType.DOCUMENTATION:
                    return <SolverMarkdown text={(turnEvent as DocumentationEvent).content.summary} />;
                case TurnEventType.CODE_COVERAGE:
                    return <CodeCoverage codeCoverageContent={(turnEvent as CodeCoverageEvent).content} />;
                case TurnEventType.EXECUTION:
                    return <Execution executionContent={(turnEvent as ExecutionEvent).content} />;
                case TurnEventType.EDIT:
                case TurnEventType.REVERT: {
                    const editChangeSet = eventChangeSets.get(turnEvent.id);

                    if (!editChangeSet || editChangeSet.state === EventChangeSetState.LOADING) {
                        return (
                            <div className="message-loading">
                                <LoadingOutlined className="message-loading-icon" />
                            </div>
                        );
                    } else if (editChangeSet.state === EventChangeSetState.ERROR) {
                        const changesContent = turnEvent.content as ChangesContent;
                        const eventType = turnEvent.event_type as TurnEventType.EDIT | TurnEventType.REVERT;

                        return (
                            <div className="info-message">
                                <strong>Failed to load message</strong>
                                <Button
                                    className="info-cta"
                                    onClick={() =>
                                        fetchChangeSet(
                                            turnEvent.id,
                                            eventType,
                                            changesContent.start,
                                            changesContent.end
                                        )
                                    }
                                >
                                    Retry
                                </Button>
                            </div>
                        );
                    }

                    return editChangeSet.changeSet.file_infos.map((fileInfo: FileInfo) => {
                        const fileData = fileInfo.fileData;
                        const diffCardKey = `${fileData.oldRevision}-${fileData.newRevision}`;
                        return <ImmutableDiffCard key={diffCardKey} fileInfo={fileInfo} />;
                    });
                }
                case TurnEventType.PLAN: {
                    const planEvent = turnEvent as unknown as PlanEvent;
                    return <Plan planContent={planEvent.content} />;
                }
                case TurnEventType.PROFILE:
                    return <Profile profileContent={(turnEvent as ProfileEvent).content} />;
                case TurnEventType.SOLUTION_REVIEW:
                    return (
                        <SolutionReview
                            solutionReview={(turnEvent as SolutionReviewEvent).content}
                            onSuggestionClick={handleSuggestionClick}
                        />
                    );
                case TurnEventType.RELEVANT_FILES: {
                    return <RelevantFilesCard relevantFilesContent={(turnEvent as RelevantFilesEvent).content} />;
                }
                case TurnEventType.LINT_ERRORS:
                    return <LinterCard content={(turnEvent as LinterErrorsEvent).content} />;
                case TurnEventType.SUBMIT:
                    return renderInfoMessage("Solver finished", turn.idx === turns.length - 1);
                case TurnEventType.RESOURCES_EXHAUSTED:
                    return renderInfoMessage("Solver paused", turn.idx === turns.length - 1);
                case TurnEventType.BLAME:
                    if (!activeRepo) {
                        return null;
                    }

                    return (
                        <Blame blameContent={(turnEvent as BlameEvent).content} fullRepoName={activeRepo.full_name} />
                    );
                case TurnEventType.BISECT:
                    return <Bisect bisectContent={(turnEvent as BisectEvent).content} />;
                case TurnEventType.WORKSPACE_CREATION_PROGRESS:
                    return (
                        <WorkspaceProgress
                            workspaceCreationProgressContent={(turnEvent as WorkspaceCreationProgressEvent).content}
                        />
                    );
                case TurnEventType.SOLVING_STOPPED:
                    return (
                        <SolvingStoppedMessage
                            solvingStoppedContent={(turnEvent as SolvingStoppedEvent).content}
                            onReportIssue={onReportIssue}
                        />
                    );
                default:
                    return <SolverMarkdown text={`\`\`\`\n${JSON.stringify(turnEvent.content, null, 2)}\n\`\`\``} />;
            }
        };

        if (!activeRepo) {
            return null;
        }

        const renderButtonContainer = (isLastTurn: boolean) =>
            isLastTurn ? (
                <div className="info-cta-container">
                    <Button className="info-cta" onClick={onShowChangesView}>
                        View repository changes
                    </Button>
                    <Button className="info-cta" onClick={() => solve("You're on the right track; please continue")}>
                        Continue Solving
                    </Button>
                </div>
            ) : null;

        const renderInfoMessage = (message: string, isLastTurn: boolean) => (
            <div className="info-message">
                <strong>{message}</strong>
                {renderButtonContainer(isLastTurn)}
            </div>
        );

        return <>{turns.map((turn: Turn) => turnThread(turn))}</>;
    }
);

Conversation.displayName = "Conversation";

export default Conversation;
