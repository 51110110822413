import { LoadingOutlined, FunctionOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { getBranches, pushInitialCommit } from "../data/Repos";
import NoBranchesModal from "./NoBranchesModal";
import { NotificationInstance } from "antd/lib/notification/interface";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import "./Conversation.css";
import PromptEditor, { FocusableRef } from "./PromptEditor";

import {
    SessionStatus,
    useCanCancelSolve,
    useCanSolve,
    useCancelSolve,
    useCreateAndSolve,
    useSession,
    useSessionNLText,
    useSessionStatus,
    useSolve,
} from "../data/SolverSession";

import { useSolverInterfaceContext } from "../data/SolverInterface";

const CustomAudioRecorder: React.FC<{ onRecordingComplete: (blob: Blob) => void; disabled: boolean }> = ({
    onRecordingComplete,
    disabled,
}) => {
    return (
        <div className={`custom-audio-recorder ${disabled ? "disabled" : ""}`}>
            {/* HACK: We swap the handlers for the submit and discard buttons to work around positioning limitations
                in react-audio-voice-recorder. The "discard" button (left) actually submits, while the
                "submit" button (right) actually discards. */}
            {!disabled && (
                <AudioRecorder
                    onRecordingComplete={onRecordingComplete}
                    audioTrackConstraints={{
                        noiseSuppression: true,
                        echoCancellation: true,
                    }}
                    downloadOnSavePress={false}
                    downloadFileExtension="wav"
                    showVisualizer={true}
                    classes={{
                        AudioRecorderClass: "audio-recorder",
                        AudioRecorderStartSaveClass: "audio-recorder-start-save custom-submit-button",
                        AudioRecorderPauseResumeClass: "display-none",
                        AudioRecorderDiscardClass: "audio-recorder-discard",
                    }}
                />
            )}
        </div>
    );
};
interface NaturalLanguageInputProps {
    notification: NotificationInstance;
}

const NaturalLanguageInput: React.FC<NaturalLanguageInputProps> = ({ notification }) => {
    const session = useSession();
    const sessionNLText = useSessionNLText();
    const sessionStatus = useSessionStatus();
    const solve = useSolve();
    const createAndSolve = useCreateAndSolve();
    const canSolve = useCanSolve();
    const cancelSolve = useCancelSolve();
    const canCancelSolve = useCanCancelSolve();

    const { currentUser, activeRepo } = useSolverInterfaceContext();

    const promptEditorRef = useRef<FocusableRef>(null);

    // Cache the prompt so that it can be updated when we solve.
    const [cachedNLText, setCachedNLText] = useState<string>("");
    const [isTranscribing, setIsTranscribing] = useState<boolean>(false);
    const [showNoBranchesModal, setShowNoBranchesModal] = useState<boolean>(false);
    const [creatingInitialCommit, setCreatingInitialCommit] = useState<boolean>(false);
    const [hasBranches, setHasBranches] = useState<boolean | null>(null);

    useEffect(() => {
        setCachedNLText(sessionNLText);
    }, [sessionNLText]);

    useEffect(() => {
        promptEditorRef.current?.focus();
    }, [session?.session_id]);

    const onPromptChange = (value: string | undefined) => setCachedNLText(value || "");

    const handleAudioStop = async (blob: Blob) => {
        setIsTranscribing(true);
        try {
            const formData = new FormData();
            formData.append("audio", blob, "audio.wav");
            const response = await solverInterfaceApiAxios.post("/transcribe", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data && response.data.transcription) {
                const transcribedText = response.data.transcription;
                setCachedNLText(transcribedText);
                // Automatically submit the transcribed text
                if (!session) {
                    if (activeRepo) {
                        createAndSolve(transcribedText, activeRepo.org, activeRepo.name, activeRepo.default_branch);
                    } else {
                        throw new Error("No active repository selected");
                    }
                } else {
                    solve(transcribedText);
                }
            } else {
                throw new Error("Transcription data is missing or invalid");
            }
        } catch (error: unknown) {
            console.error("Error during transcription:", error);
            let errorMessage = "An error occurred while transcribing the audio.";
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    errorMessage = `Server error: ${error.response.status}`;
                } else if (error.request) {
                    errorMessage = "No response received from the server.";
                } else {
                    errorMessage = error.message || "Unknown error occurred";
                }
            } else if (error instanceof Error) {
                errorMessage = error.message;
            }
            notification.error({
                message: "Transcription failed",
                description: errorMessage,
                placement: "bottomRight",
            });
        } finally {
            setIsTranscribing(false);
        }
    };

    const buildButton = () => {
        switch (sessionStatus) {
            case SessionStatus.READY:
                return buildSolveButton();
            case SessionStatus.SUBMITTING_SOLVE:
                return buildSubmittingSolveButton();
            case SessionStatus.PENDING:
            case SessionStatus.SOLVING:
                return buildCancelSolveButton();
            case SessionStatus.SUBMITTING_CANCEL:
                return buildCancellingSolveButton();
            case SessionStatus.ARCHIVED:
                return buildArchivedButton();
            default:
                return buildSolveButton();
        }
    };

    const buildSolveButton = () => {
        return (
            <Button
                id="tour-solve-button"
                className="solve-button"
                type="primary"
                onClick={(e) => {
                    onSolve();
                    e.stopPropagation();
                }}
                disabled={!canSolve(cachedNLText)}
            >
                <FunctionOutlined /> Solve
            </Button>
        );
    };

    const buildSubmittingSolveButton = () => {
        return (
            <Button className="solve-button" type="primary" disabled={true}>
                <LoadingOutlined /> Submitting
            </Button>
        );
    };

    const buildCancelSolveButton = () => {
        return (
            <Button
                className="solve-button"
                onClick={(e) => {
                    onCancelSolve();
                    e.stopPropagation();
                }}
                disabled={!canCancelSolve()}
            >
                <LoadingOutlined /> Cancel
            </Button>
        );
    };

    const buildCancellingSolveButton = () => {
        return (
            <Button className="solve-button" disabled={true}>
                <LoadingOutlined /> Cancelling
            </Button>
        );
    };

    const buildArchivedButton = () => {
        return (
            <Tooltip title="This session has been archived and can no longer be solved.">
                <Button className="solve-button" disabled={true}>
                    Archived
                </Button>
            </Tooltip>
        );
    };

    // Reset hasBranches when activeRepo changes
    useEffect(() => {
        setHasBranches(null);
    }, [activeRepo]);

    const onSolve = async () => {
        if (!currentUser || !activeRepo) return;

        if (!session) {
            // Only check branches if we haven't checked before or if we previously found no branches
            if (hasBranches === null || hasBranches === false) {
                try {
                    const branches = await getBranches(activeRepo.org, activeRepo.name, 250);
                    const hasAnyBranches = branches.length > 0;
                    setHasBranches(hasAnyBranches);

                    if (!hasAnyBranches) {
                        setShowNoBranchesModal(true);
                        return;
                    }
                } catch (error) {
                    notification.error({
                        message: "Failed to check repository branches",
                        placement: "bottomRight",
                    });
                    return;
                }
            }
            createAndSolve(cachedNLText, activeRepo.org, activeRepo.name, activeRepo.default_branch);
        } else {
            solve(cachedNLText);
        }
    };

    const handleCreateInitialCommit = async () => {
        if (!activeRepo) return;

        setCreatingInitialCommit(true);
        try {
            await pushInitialCommit(activeRepo.org, activeRepo.name);
            setShowNoBranchesModal(false);
            setHasBranches(true); // We know we just created a branch
            createAndSolve(cachedNLText, activeRepo.org, activeRepo.name, activeRepo.default_branch);
            notification.success({
                message: "Initial commit created",
                description: `You can now Solve in ${activeRepo.name}`,
                placement: "bottomRight",
            });
        } catch (error) {
            notification.error({
                message: "Failed to create initial commit",
                description: error instanceof Error ? error.message : "An unknown error occurred",
                placement: "bottomRight",
            });
        } finally {
            setCreatingInitialCommit(false);
        }
    };

    const onCancelSolve = () => {
        if (!canCancelSolve()) return;

        cancelSolve();
    };

    const onPromptEditorKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (processSolveHotkey(e)) return;
    };

    const processSolveHotkey = (e: React.KeyboardEvent<HTMLElement>): boolean => {
        if ((e.ctrlKey || e.metaKey) && e.key === "Enter" && canSolve(cachedNLText)) {
            onSolve();
            (e.target as HTMLTextAreaElement).blur();
            e.preventDefault();
            return true;
        }

        return false;
    };

    return (
        <div
            className="nl-text-container"
            onClick={() => {
                promptEditorRef.current?.focus();
            }}
        >
            <PromptEditor
                ref={promptEditorRef}
                value={cachedNLText}
                onChange={onPromptChange}
                onKeyDown={onPromptEditorKeyDown}
                placeholder="Write a task, pose a problem, or ask a question"
                disabled={session?.is_read_only}
            />
            <div className="solve-button-container">
                <div className={`audio-recorder-container ${isTranscribing ? "transcribing" : ""}`}>
                    <CustomAudioRecorder
                        onRecordingComplete={handleAudioStop}
                        disabled={session?.is_read_only || isTranscribing}
                    />
                    {isTranscribing && <LoadingOutlined className="transcribing-loader" />}
                </div>
                {buildButton()}
            </div>
            <NoBranchesModal
                isOpen={showNoBranchesModal}
                onClose={() => setShowNoBranchesModal(false)}
                onCreateInitialCommit={handleCreateInitialCommit}
                isCreating={creatingInitialCommit}
            />
        </div>
    );
};

export default NaturalLanguageInput;
