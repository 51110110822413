import React, { useEffect, useState } from "react";

import {
    LoadingOutlined,
    CheckCircleOutlined,
    ContainerOutlined,
    ClockCircleOutlined,
    ExclamationCircleOutlined,
    DeleteOutlined,
    CodeOutlined,
} from "@ant-design/icons";
import { Tooltip, Typography, Card } from "antd";

import classNames from "classnames";
import { formatDistanceToNow } from "date-fns";

import AvatarVariantFactory from "../data/AvatarVariantFactory";
import { Session, sessionIsLoading, SessionStatus } from "../data/SolverSession";
import { User } from "../data/User";

import "./SessionCard.css";

const SessionCard: React.FC<{
    currentUser?: User;
    session: Session;
    active: boolean;
    onDelete: () => void;
    onClone: () => void;
}> = ({ currentUser, session, active, onDelete, onClone }) => {
    const [timeAgoMessage, setTimeAgoMessage] = useState<string>(
        formatDistanceToNow(new Date(session.modify_timestamp * 1000), { addSuffix: true })
    );

    useEffect(() => {
        const interval = setInterval(
            () =>
                setTimeAgoMessage(formatDistanceToNow(new Date(session.modify_timestamp * 1000), { addSuffix: true })),
            5000
        );
        return () => clearInterval(interval);
    }, [session.modify_timestamp]);

    const getStatusIcon = () => {
        switch (session.status) {
            case SessionStatus.READY:
                return <CheckCircleOutlined className="session-status-icon" />;
            case SessionStatus.ARCHIVED:
                return <ContainerOutlined className="session-status-icon" />;
            case SessionStatus.PENDING:
                return <ClockCircleOutlined className="session-status-icon" />;
            case SessionStatus.SOLVING:
            case SessionStatus.SUBMITTING_CANCEL:
            case SessionStatus.SUBMITTING_SOLVE:
                return <LoadingOutlined className="session-status-icon session-status-icon-loading" />;
            default:
                return <ExclamationCircleOutlined className="session-status-icon" />;
        }
    };

    const getStatusTooltip = () => {
        switch (session.status) {
            case SessionStatus.READY:
                return "Ready to solve";
            case SessionStatus.PENDING:
                return "Pending";
            case SessionStatus.SUBMITTING_SOLVE:
                return "Submitting";
            case SessionStatus.SOLVING:
                return "Solving";
            case SessionStatus.SUBMITTING_CANCEL:
                return "Cancelling";
            case SessionStatus.ARCHIVED:
                return "Archived";
            default:
                return "Unknown status";
        }
    };

    const buildTitleArea = () => {
        return (
            <div className="session-card-title-area">
                <div className="session-card-title">{buildTitle()}</div>
                <div className="session-card-branch-area">
                    <Tooltip
                        title={`${session.branch_name} @ ${session.base_revision.slice(0, 7)}`}
                        arrow={false}
                        placement="topLeft"
                    >
                        <small className="session-card-branch-name">{session.branch_name}</small>
                    </Tooltip>
                    {session.remote_branch_name && (
                        <>
                            &larr;
                            <Tooltip title={session.remote_branch_name} arrow={false} placement="topLeft">
                                <small className="session-card-branch-name">{session.remote_branch_name}</small>
                            </Tooltip>
                        </>
                    )}
                </div>
            </div>
        );
    };

    const buildTitle = () => {
        const titleTextclass = classNames({
            "session-card-title-text-inactive": !active,
            "session-card-title-text-active": active,
        });

        return (
            <Tooltip title={session.title} placement="top" arrow={false}>
                <Typography.Text className={titleTextclass}>{session.title}</Typography.Text>
            </Tooltip>
        );
    };

    const buildActionButtons = () => [];

    const buildCardDescription = () => {
        const avatarUrl = AvatarVariantFactory.createURLVariant(session.user_avatar_url, session.auth_type, 40);

        return (
            <div className="session-card-description">
                <span className="session-card-author">
                    <img className="session-card-author-icon" src={avatarUrl} alt={session.user_name} />
                    <small>{session.user_name}</small>
                </span>
                <small className="session-card-timestamp">Updated {timeAgoMessage}</small>
            </div>
        );
    };

    const buildActionButton = () => {
        const canDeleteOrClone = !sessionIsLoading(session.status);
        const buttonClass = classNames({
            "session-card-hover": true,
            "session-card-hover-disabled": !canDeleteOrClone,
        });

        if (currentUser?.id === session.user_id) {
            return (
                <Tooltip
                    title={canDeleteOrClone ? "Delete" : "Cannot delete while solving"}
                    arrow={false}
                    placement="right"
                >
                    <div
                        className={buttonClass}
                        onClick={(e) => {
                            if (canDeleteOrClone) {
                                onDelete();
                            }

                            e.stopPropagation();
                        }}
                    >
                        <DeleteOutlined />
                    </div>
                </Tooltip>
            );
        } else if (session.status === SessionStatus.READY) {
            return (
                <Tooltip
                    title={canDeleteOrClone ? "Clone" : "Cannot clone while solving"}
                    arrow={false}
                    placement="right"
                >
                    <div
                        className={buttonClass}
                        onClick={(e) => {
                            if (canDeleteOrClone) {
                                onClone();
                            }

                            e.stopPropagation();
                        }}
                    >
                        <CodeOutlined />
                    </div>
                </Tooltip>
            );
        }
        return null;
    };

    const cardClasses = classNames({
        "session-card": true,
        "session-card-active": active,
    });

    return (
        <Card size="small" actions={buildActionButtons()} className={cardClasses} title={buildTitleArea()}>
            <div className="session-card-body">
                <Tooltip title={getStatusTooltip()} placement="right">
                    {getStatusIcon()}
                </Tooltip>
                {buildCardDescription()}
                {buildActionButton()}
            </div>
        </Card>
    );
};

export default SessionCard;
