import { Button, Modal } from "antd";
import React from "react";

interface NoBranchesModalProps {
    isOpen: boolean;
    onClose: () => void;
    onCreateInitialCommit: () => void;
    isCreating?: boolean;
}

const NoBranchesModal: React.FC<NoBranchesModalProps> = ({ isOpen, onClose, onCreateInitialCommit, isCreating }) => {
    return (
        <Modal
            title="No Branches Found"
            open={isOpen}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="create" type="primary" onClick={onCreateInitialCommit} loading={isCreating}>
                    {isCreating ? "Creating Initial Commit..." : "Create Initial Commit"}
                </Button>,
            ]}
        >
            <p>
                Solver requires a branch with at least one commit to operate. This repository doesn't have any branches
                yet.
            </p>
            <p>
                Would you like to create an initial branch with a README.md file? This will allow Solver to start
                working with your repository.
            </p>
        </Modal>
    );
};

export default NoBranchesModal;
