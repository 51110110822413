import React, { useEffect, useRef, useState } from "react";

import { CameraOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Layout, Modal, notification, Radio } from "antd";

import classNames from "classnames";
import html2canvas from "html2canvas";

import ActiveSessionControls from "./ActiveSessionControls";
import ActiveSessionTitle from "./ActiveSessionTitle";
import ChangesView from "./ChangesView";
import Conversation from "./Conversation";
import NaturalLanguageInput from "./NaturalLanguageInput";
import PromptSuggestionChips from "./PromptSuggestionChips";
import ReportModal from "./ReportModal";

import { getPromptSuggestions, PromptSuggestion } from "../data/Repos";
import { useSessionBrowsingContext } from "../data/SessionBrowsing";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import {
    LoadingSessionState,
    useCreateAndSolve,
    useLoadingSessionState,
    useRefreshTurns,
    useSession,
    useSessionStatus,
    useSolve,
    useTurns,
    sessionIsLoading,
} from "../data/SolverSession";

const { Content } = Layout;

import "./ActiveSession.css";

interface ActiveSessionProps {}

const ActiveSession: React.FC<ActiveSessionProps> = () => {
    const { activeRepo, currentUser, repos } = useSolverInterfaceContext();
    const { updateTitle } = useSessionBrowsingContext();
    const session = useSession();
    const sessionStatus = useSessionStatus();
    const turns = useTurns();
    const loadingSessionState = useLoadingSessionState();
    const createAndSolve = useCreateAndSolve();
    const solve = useSolve();
    const refreshTurns = useRefreshTurns();

    const sessionContentRef = useRef<HTMLDivElement>(null);

    const [api, contextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();

    const [viewMode, setViewMode] = useState<"conversation" | "changes" | "photo">("conversation");
    // TODO: When we add navigation, we should add the scroll position to the state
    // object in history.pushState calls. This way it will be preserved in between active
    // sessions.
    const [conversationScrollPosition, setConversationScrollPosition] = useState<number>(0);
    const [changesScrollPosition, setChangesScrollPosition] = useState<number>(0);
    const [promptSuggestions, setPromptSuggestions] = useState<PromptSuggestion[]>([]);
    // Reset viewMode to "conversation" when the active session changes
    useEffect(() => {
        setViewMode("conversation");
        setConversationScrollPosition(0);
        setChangesScrollPosition(0);
    }, [session?.session_id]);

    const [reportModalOpen, setReportModalOpen] = useState(false);

    useEffect(() => {
        setReportModalOpen(false);
    }, [session?.session_id]);

    // Fetch prompt suggestions when the active repo changes
    useEffect(() => {
        if (activeRepo && (!session || turns.length === 0)) {
            getPromptSuggestions(activeRepo.org, activeRepo.name)
                .then((suggestions) => {
                    setPromptSuggestions(suggestions);
                })
                .catch(() => {
                    setPromptSuggestions([]);
                });
        } else {
            setPromptSuggestions([]);
        }
    }, [session, turns, activeRepo]);

    const solveSuggestion = (suggestion: string) => {
        if (!activeRepo) {
            return;
        }

        if (session) {
            solve(suggestion);
        } else {
            createAndSolve(suggestion, activeRepo.org, activeRepo.name, activeRepo.default_branch);
        }
    };

    const handleScreenshot = () => {
        html2canvas(document.getElementById("root") as HTMLElement, { useCORS: true }).then((canvas) => {
            const link = document.createElement("a");
            link.href = canvas.toDataURL("image/png");
            link.download = "screenshot.png";
            link.click();
        });
    };

    const sessionContent = () => {
        switch (viewMode) {
            case "changes":
                return (
                    <ChangesView
                        restoreScrollPosition={() => {
                            sessionContentRef.current?.scrollTo(0, changesScrollPosition);
                        }}
                    />
                );
            case "conversation":
            case "photo":
            default:
                return (
                    <Conversation
                        ref={sessionContentRef}
                        restoreScrollPosition={() => {
                            sessionContentRef.current?.scrollTo(0, conversationScrollPosition);
                        }}
                        onShowChangesView={() => setViewMode("changes")}
                        onReportIssue={() => setReportModalOpen(true)}
                    />
                );
        }
    };

    if (repos.length === 0) {
        return <Content className="no-active-session" />;
    }

    const buildWelcomeMessage = () => {
        const welcomeMessageContainerClasses = classNames({
            "welcome-message-container": true,
            "welcome-message-container-small": promptSuggestions.length > 0,
        });

        return (
            <Layout className="session-conversation-layout">
                <Content className="no-active-session">
                    <div className="new-session-message">
                        <div className="new-session-solver-logo"></div>
                        <div className={welcomeMessageContainerClasses}>
                            <p className="welcome-message-line">Describe your task</p>
                            <p className="welcome-message-line">Start Solving</p>
                            {promptSuggestions.length > 0 && (
                                <p className="welcome-message-line">A few ideas to get started:</p>
                            )}
                        </div>
                        <PromptSuggestionChips
                            promptSuggestions={promptSuggestions}
                            onClick={(suggestion) => solveSuggestion(suggestion)}
                        />
                    </div>
                </Content>
                <div className="input-container" id="tour-task-input">
                    <NaturalLanguageInput notification={api} />
                </div>
                {contextHolder}
            </Layout>
        );
    };

    const buildSessionContent = () => {
        if (!session) {
            return null;
        }

        const layoutClass = classNames({
            "session-full-height-layout": viewMode === "photo",
            "session-conversation-layout": true,
        });

        const sessionTitle = session ? session.title : "New Session";

        const leftControlsClasses = classNames({
            "active-session-controls": true,
            "active-session-controls-left": true,
        });

        return (
            <Layout className={layoutClass}>
                <div className="active-session-title-bar">
                    <ActiveSessionControls
                        classes={leftControlsClasses}
                        session={session}
                        exportDisabled={sessionIsLoading(sessionStatus)}
                        onReportIssue={() => setReportModalOpen(true)}
                        onPulledRemoteChanges={() => refreshTurns()}
                        notification={api}
                        modal={modal}
                    />
                    <ActiveSessionTitle
                        sessionTitle={sessionTitle}
                        editable={currentUser?.id === session.user_id}
                        onUpdateTitle={async (title) => {
                            if (session) {
                                return updateTitle(session, title);
                            } else {
                                console.error("No active session to update title");
                                return false;
                            }
                        }}
                        notification={api}
                    />
                    <span className="active-session-controls">
                        {viewMode === "photo" && (
                            <Button onClick={handleScreenshot} type="text" icon={<CameraOutlined />} />
                        )}
                        <Radio.Group
                            size="small"
                            onChange={(e) => {
                                setViewMode((prevViewMode) => {
                                    if (prevViewMode === "conversation") {
                                        setConversationScrollPosition(sessionContentRef.current?.scrollTop || 0);
                                    } else if (prevViewMode === "changes") {
                                        setChangesScrollPosition(sessionContentRef.current?.scrollTop || 0);
                                    }

                                    return e.target.value;
                                });
                            }}
                            value={viewMode}
                            optionType="button"
                            disabled={!session || turns.length === 0}
                        >
                            <Radio.Button className="active-session-controls-radio" value="conversation">
                                Conversation
                            </Radio.Button>
                            <Radio.Button className="active-session-controls-radio" value="changes">
                                Changes
                            </Radio.Button>
                            <Radio.Button className="active-session-controls-radio" value="photo">
                                Photo
                            </Radio.Button>
                        </Radio.Group>
                    </span>
                </div>
                {turns.length > 0 && (
                    <div className="session-content scrollbar scrollbar-gutter-stable-both" ref={sessionContentRef}>
                        {sessionContent()}
                    </div>
                )}
                <div className="input-container" id="tour-task-input">
                    <NaturalLanguageInput notification={api} />
                </div>
                {contextHolder}
                {modalContextHolder}
                <ReportModal
                    sessionInfo={session.getInfo()}
                    modalOpen={reportModalOpen}
                    onOpenChange={setReportModalOpen}
                    notification={api}
                />
            </Layout>
        );
    };

    switch (loadingSessionState) {
        case LoadingSessionState.ERROR:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">Failed to load session</span>
                </Content>
            );
        case LoadingSessionState.NOT_FOUND:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">Session not found</span>
                </Content>
            );
        case LoadingSessionState.LOADING:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">
                        <LoadingOutlined style={{ fontSize: "40px" }} />
                    </span>
                </Content>
            );
        case LoadingSessionState.DONE:
            if (!session || (session && turns.length === 0)) {
                return buildWelcomeMessage();
            } else {
                return buildSessionContent();
            }
    }
};

export default ActiveSession;
