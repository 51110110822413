"use client";
import React from "react";
import { FileData, parseDiff } from "react-diff-view";

import { MergeUserBranchContent } from "../../data/TurnEventContent";
import { ImmutableDiffCard } from "../ImmutableDiffCard";

import { FileInfo } from "../Utils";

import { buildCommitLabel } from "./Common";
import { InlineMessage } from "./InlineMessage";
import { MergeOutlined } from "@ant-design/icons";

import "./Common.css";
import { Collapse } from "antd";

interface UserBranchMergeProps {
    content: MergeUserBranchContent;
    userBranchName: string;
}

const UserBranchMerge: React.FC<UserBranchMergeProps> = ({ content, userBranchName }) => {
    const buildDiffs = (content: MergeUserBranchContent) => {
        const fileDatas: FileData[] = parseDiff(content.patch);

        const fileInfos: FileInfo[] = fileDatas.map((fileData: FileData) => {
            return {
                fileData: fileData,
                source: null,
                fileLines: 0,
                change_ids: [],
                rawPatch: content.patch,
            };
        });

        return fileInfos.map((fileInfo: FileInfo) => {
            const diffCardKey = (fileData: FileData) => `${fileData.oldRevision}-${fileData.newRevision}`;
            return (
                <ImmutableDiffCard
                    key={diffCardKey(fileInfo.fileData)}
                    fileInfo={fileInfo}
                    expandCodeFn={() => {}}
                    collapsable={true}
                />
            );
        });
    };

    const buildContent = () => {
        return (
            <Collapse
                className="commits-collapse"
                defaultActiveKey={[]}
                accordion={true}
                items={[
                    {
                        key: "0",
                        label: buildCommitLabel("Solver", content.date, "Merged changes"),
                        children: buildDiffs(content),
                    },
                ]}
            />
        );
    };

    const messageText = content.patch
        ? `Merged changes from ${userBranchName}.`
        : `Merged changes from ${userBranchName}, but the diff was empty.`;

    return (
        <div>
            <InlineMessage icon={<MergeOutlined />} text={messageText} />
            {content.patch && buildContent()}
        </div>
    );
};

export default UserBranchMerge;
