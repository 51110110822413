"use client";
import React from "react";

import Convert from "ansi-to-html";
import { Typography } from "antd";

import "./Terminal.css";

const convert = new Convert({ newline: true });

interface TerminalProps {
    command?: string;
    stdout?: string;
    stderr?: string;
    exitCode: number;
    completed: boolean;
    error_msg?: string;
}

const Terminal: React.FC<TerminalProps> = ({ command, stdout, stderr, exitCode, completed, error_msg }) => {
    return (
        <div>
            {command && (
                <pre className="terminal">
                    $ {command}
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: stdout ? convert.toHtml(stdout) : "" }} />
                </pre>
            )}
            {stderr && (
                <div>
                    <Typography.Text type="danger" className="terminal-label">
                        Stderr:
                    </Typography.Text>
                    <pre className="terminal danger">
                        <span dangerouslySetInnerHTML={{ __html: convert.toHtml(stderr) }} />
                    </pre>
                </div>
            )}
            {completed && (exitCode >= 0 || !error_msg) && (
                <Typography.Text type={exitCode === 0 ? "success" : "danger"} className="terminal-label">
                    Exit Code: {exitCode}
                </Typography.Text>
            )}
            {completed && error_msg && (
                <Typography.Text type="danger" className="terminal-label">
                    {error_msg}
                </Typography.Text>
            )}
        </div>
    );
};

export default Terminal;
