"use client";
import React, { useEffect, useState } from "react";

import {
    CaretDownOutlined,
    LoadingOutlined,
    PlusCircleOutlined,
    QuestionCircleOutlined,
    SearchOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Dropdown, Input, InputRef, Row, Tooltip } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import classNames from "classnames";

import { NavigationBehavior } from "../data/Navigation";
import { Repo } from "../data/Repos";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import { useLoadSession } from "../data/SolverSession";
import { AuthType } from "../data/User";
import FAQModal from "./FAQModal";
import NewRepoModal from "./NewRepoModal";
import RepoCard from "./RepoCard";
import SettingsPopover from "./SettingsPopover";
import SolverLogo from "./SolverLogo";

const LOCAL_STORAGE_KEY = "shownFAQ";

const filterRepo = (repo: Repo, filter: string) => {
    if (filter.trim() === "") {
        return true;
    }

    return repo.name.toLowerCase().includes(filter.trim().toLowerCase());
};

interface SolverHeaderProps {
    onFaqClose: () => void;
}

const SolverHeader: React.FC<SolverHeaderProps> = ({ onFaqClose }) => {
    const loadSession = useLoadSession();
    const {
        currentUser,
        loggedIn,
        repos,
        activeRepo,
        loadingRepos,
        loadingAdditionalRepos,
        setActiveRepo,
        activateRepo,
        userNotAllowListed,
    } = useSolverInterfaceContext();

    const [settingsOpen, setSettingsOpen] = React.useState<boolean>(false);
    const [faqOpen, setFaqOpen] = useState<boolean>(false);
    const [repoDropdownOpen, setRepoDropdownOpen] = useState<boolean>(false);
    const [repoDropdownTab, setRepoDropdownTab] = useState<"active" | "inactive" | "demo">("active");
    const [repoFilter, setRepoFilter] = useState<string>("");
    const inputRef = React.useRef<InputRef>(null);
    const [newRepoModalOpen, setNewRepoModalOpen] = useState<boolean>(false);

    // Ensure input ref is ready when dropdown opens
    useEffect(() => {
        if (repoDropdownOpen && inputRef.current) {
            requestAnimationFrame(() => {
                inputRef.current?.focus();
            });
        }
    }, [repoDropdownOpen]);

    useEffect(() => {
        const hasLoggedInBefore = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (loggedIn && !hasLoggedInBefore) {
            setFaqOpen(true);
            localStorage.setItem(LOCAL_STORAGE_KEY, "true");
        }
    }, [loggedIn]);

    const setActiveRepoAndNavigate = async (repo: Repo) => {
        if (repo.full_name === activeRepo?.full_name) {
            setRepoDropdownOpen(false);
            return;
        }

        if (!repo.is_activated) {
            const activated = await activateRepo(repo);

            if (!activated) {
                return;
            }
        }

        setActiveRepo(repo, NavigationBehavior.PUSH);
        loadSession(undefined, NavigationBehavior.NONE);
        setRepoDropdownOpen(false);
        setRepoFilter("");
    };

    const demoRepos: Repo[] = [];
    const groupedActiveRepos: { [org: string]: Repo[] } = {};
    const groupedInactiveRepos: { [org: string]: Repo[] } = {};

    repos.forEach((repo) => {
        if (!filterRepo(repo, repoFilter)) {
            return;
        }
        if (repo.is_demo) {
            demoRepos.push(repo);
            return;
        }

        const targetGroup = repo.is_activated ? groupedActiveRepos : groupedInactiveRepos;
        if (!targetGroup[repo.org]) {
            targetGroup[repo.org] = [];
        }

        if (filterRepo(repo, repoFilter)) {
            targetGroup[repo.org].push(repo);
        }
    });

    const buildHeaderActions = () => {
        return (
            <Row wrap={false} align="middle" className="header-action-bar">
                {loggedIn && (
                    <Tooltip title="FAQ" arrow={false}>
                        <Button
                            type="text"
                            icon={<QuestionCircleOutlined />}
                            onClick={() => setFaqOpen(true)}
                            id="tour-faq-button"
                        />
                    </Tooltip>
                )}
                {loggedIn && buildDropdownButton()}
                {(loggedIn || userNotAllowListed) && buildSettingsButton()}
            </Row>
        );
    };

    const handleRepoFilter = (newFilter: string) => {
        setRepoFilter(newFilter);
        if (!newFilter.trim()) {
            setRepoDropdownTab("active");
            return;
        }

        const hasOnlyDemoMatches =
            demoRepos.length > 0 &&
            Object.values(groupedActiveRepos).flat().length === 0 &&
            Object.values(groupedInactiveRepos).flat().length === 0;

        if (hasOnlyDemoMatches) {
            setRepoDropdownTab("demo");
        }
    };

    const buildMenuItems = () => {
        if (loadingAdditionalRepos) {
            return [
                {
                    icon: (
                        <div className="repo-dropdown-non-selectable-label">
                            <LoadingOutlined />
                            Loading repos...
                        </div>
                    ),
                    key: "loading",
                },
            ];
        }

        const menuItems: ItemType[] = [];
        menuItems.push({
            icon: (
                <div className="repo-dropdown-non-selectable-label">
                    <Input
                        value={repoFilter}
                        onChange={(e) => handleRepoFilter(e.target.value)}
                        ref={inputRef}
                        placeholder="Search repositories"
                        addonBefore={<SearchOutlined />}
                        allowClear
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                const allFilteredRepos = [
                                    ...Object.values(groupedActiveRepos).flat(),
                                    ...Object.values(groupedInactiveRepos).flat(),
                                    ...demoRepos,
                                ];
                                if (allFilteredRepos.length === 1) {
                                    setActiveRepoAndNavigate(allFilteredRepos[0]);
                                }
                            }
                        }}
                    />
                </div>
            ),
            key: "filter-repos",
        });

        const activeRepoCount = Object.values(groupedActiveRepos).reduce((sum, repos) => sum + repos.length, 0);
        const inactiveRepoCount = Object.values(groupedInactiveRepos).reduce((sum, repos) => sum + repos.length, 0);

        const tabs = [];
        if (activeRepoCount > 0) {
            tabs.push(
                <Button
                    className={classNames({
                        "repo-dropdown-tab-button": true,
                        "repo-dropdown-tab-button-active": repoDropdownTab === "active",
                    })}
                    onClick={(e) => {
                        setRepoDropdownTab("active");
                        e.stopPropagation();
                    }}
                >
                    Active ({activeRepoCount})
                </Button>
            );
        }

        if (inactiveRepoCount > 0) {
            if (tabs.length > 0) {
                tabs.push(<Divider type="vertical" className="repo-dropdown-tabs-divider" />);
            }
            tabs.push(
                <Button
                    className={classNames({
                        "repo-dropdown-tab-button": true,
                        "repo-dropdown-tab-button-active": repoDropdownTab === "inactive",
                    })}
                    onClick={(e) => {
                        setRepoDropdownTab("inactive");
                        e.stopPropagation();
                    }}
                >
                    Inactive ({inactiveRepoCount})
                </Button>
            );
        }

        if (demoRepos.length > 0) {
            if (tabs.length > 0) {
                tabs.push(<Divider type="vertical" className="repo-dropdown-tabs-divider" />);
            }
            tabs.push(
                <Button
                    className={classNames({
                        "repo-dropdown-tab-button": true,
                        "repo-dropdown-tab-button-active": repoDropdownTab === "demo",
                    })}
                    onClick={(e) => {
                        setRepoDropdownTab("demo");
                        e.stopPropagation();
                    }}
                >
                    Demo ({demoRepos.length})
                </Button>
            );
        }

        if (tabs.length > 0) {
            menuItems.push({
                icon: <div className="repo-dropdown-tabs">{tabs}</div>,
                key: "repo-type",
                onClick: () => {},
            });
        }

        if (repoDropdownTab === "demo") {
            if (demoRepos.length === 0) {
                menuItems.push({
                    icon: <div className="repo-dropdown-non-selectable-label">No demo repos found</div>,
                    key: "no-demo-repos",
                });
            } else {
                demoRepos.forEach((repo) => {
                    menuItems.push({
                        label: repo.name,
                        key: `demo-${repo.name}`,
                        onClick: () => {
                            setActiveRepoAndNavigate(repo);
                        },
                    });
                });
            }
        } else {
            const currentGroup = repoDropdownTab === "active" ? groupedActiveRepos : groupedInactiveRepos;
            if (Object.keys(currentGroup).length === 0) {
                menuItems.push({
                    icon: <div className="repo-dropdown-non-selectable-label">No {repoDropdownTab} repos found</div>,
                    key: `no-${repoDropdownTab}-repos`,
                });
            } else {
                Object.entries(currentGroup).forEach(([org, orgRepos], orgIndex) => {
                    if (orgIndex > 0) {
                        menuItems.push({ type: "divider" });
                    }

                    menuItems.push({
                        type: "group",
                        label: org,
                        children: orgRepos.map((repo, index) => ({
                            label: repo.name,
                            key: `${org}-${index}`,
                            onClick: () => {
                                setActiveRepoAndNavigate(repo);
                            },
                        })),
                    });
                });
            }

            if (currentUser?.auth_type === AuthType.GitHub) {
                if (menuItems.length > 0) {
                    menuItems.push({ type: "divider" });
                }

                menuItems.push({
                    icon: (
                        <div className="repo-dropdown-add-repo-label" onClick={() => setNewRepoModalOpen(true)}>
                            <PlusCircleOutlined />
                            Add a repo
                        </div>
                    ),
                    key: "add-repo",
                });
            }
        }
        return menuItems;
    };

    const buildDropdownButton = () => {
        const menuItems = buildMenuItems();

        return (
            <div id="tour-repo-select">
                <Dropdown
                    disabled={!loggedIn || loadingRepos}
                    menu={{
                        items: menuItems,
                    }}
                    open={repoDropdownOpen}
                    onOpenChange={setRepoDropdownOpen}
                    overlayClassName="repo-dropdown-overlay scrollbar scrollbar-gutter-stable"
                    placement="bottomRight"
                    className="repo-dropdown"
                    trigger={["click"]}
                >
                    <div>
                        {dropdownButtonContent()}
                        {menuItems && menuItems.length > 0 && <CaretDownOutlined className="repo-dropdown-caret" />}
                    </div>
                </Dropdown>
            </div>
        );
    };

    const dropdownButtonContent = () => {
        if (!loggedIn) {
            return undefined;
        } else if (loadingRepos) {
            return <LoadingOutlined className="loading-repos-icon" />;
        } else if (activeRepo) {
            return <RepoCard repo={activeRepo} />;
        } else if (repos.length === 0) {
            return "No Repos Found";
        }
    };
    const buildSettingsButton = () => {
        return (
            <div id="tour-settings-button">
                <SettingsPopover popoverOpen={settingsOpen} onOpenChange={setSettingsOpen}>
                    <Button className="settings-btn" onClick={() => setSettingsOpen(!settingsOpen)}>
                        <SettingOutlined />
                    </Button>
                </SettingsPopover>
            </div>
        );
    };

    return (
        <>
            <Row wrap={false} align="middle" justify="space-between" className="solver-header">
                <Col>
                    <SolverLogo onClick={() => loadSession(undefined, NavigationBehavior.PUSH)} />
                </Col>
                <Col>{buildHeaderActions()}</Col>
            </Row>

            <FAQModal
                open={faqOpen}
                onClose={() => {
                    setFaqOpen(false);
                    onFaqClose();
                }}
            />
            <NewRepoModal
                open={newRepoModalOpen}
                onClose={() => setNewRepoModalOpen(false)}
                authType={currentUser?.auth_type || AuthType.GitHub}
            />
        </>
    );
};

export default SolverHeader;
