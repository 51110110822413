import React from "react";
import { App, Button, Col, ConfigProvider, Row } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import SolverLogo from "./components/SolverLogo";

import { APP_THEME } from "./AppTheme";

const ErrorFallback: React.FC<{ error: Error }> = () => {
    return (
        <ConfigProvider theme={APP_THEME}>
            <App>
                <Row wrap={false} align="middle" className="solver-header">
                    <Col flex="auto">
                        <SolverLogo onClick={() => {}} />
                    </Col>
                </Row>
                <div className="startup">
                    <div className="startup-text">
                        Something went wrong. Please reload and try again, or reach out to us for support.
                    </div>
                    <Button onClick={() => window.location.reload()}>
                        <ReloadOutlined /> Reload
                    </Button>
                </div>
            </App>
        </ConfigProvider>
    );
};

export default ErrorFallback;
